import React from 'react'
import integration from '../images/platform-imgs/Integrate.png'
import automation from '../images/platform-imgs/automation1.png'
import reporting from '../images/platform-imgs/Report-.png'
import governance from '../images/platform-imgs/governance.png'
import logo from '../images/logo-sk.png'
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import styles from './ProductDetails.module.css';

let listyle = {
    backgroundColor: ' #183869',
}
let btncolor = {
    backgroundColor: 'red',
}

let borderTop = {
    borderTop: '0'
}

function createStyles(i) {
    let divStyles = [listyle, listyle, listyle, listyle];

    if (i >= 0)
        divStyles[i] = btncolor;
    return divStyles;
}

function selectedContent(i) {
    switch (i) {
        case 0:
            return (

                <div id="Output-Data-Formats" >
                    < div className="table-responsive tr-bg" >
                        <table className={`table ${styles.table}`}>
                            <thead>

                                <tr>
                                    <th className={styles.th}>Integration</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td style={borderTop}>Read / write xls, txt, csv, dbf, xml, json files </td>
                                </tr>
                                <tr>
                                    <td style={borderTop}>Connect to SQL servers, PostGreSQL, Oracle, MySQL and other databases</td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Connect to Exchange and other email servers over IMAP and SMTP</td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Read/write from shared folders, FTP / SFTP</td>
                                </tr>
                                <tr>
                                    <td style={borderTop}>Consume and expose APIs</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            )

        case 1:
            return (
                <div id="Import-Data-Formats" >
                    < div className="table-responsive tr-bg" >
                        <table className={`table ${styles.table}`}>
                            <thead>
                                <tr>
                                    <th  className={styles.th}>
                                        Automation
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td style={borderTop}>Perform complex chains of calculations </td>
                                </tr>
                                <tr>
                                    <td style={borderTop}>Support for data transformation- filter, sort, pivot, lookup </td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Support for Excel functions including financial functions </td>
                                </tr>


                                <tr>
                                    <td style={borderTop}>Automation of task execution based on incoming emails or time based schedules </td>
                                </tr>
                                <tr>
                                    <td style={borderTop}>Send emails / SMS </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            )
        case 2:
            return (
                <div id="Server-Capabilities" >
                    < div className="table-responsive tr-bg" >
                        <table className={`table ${styles.table}`}>
                            <thead>
                                <tr>
                                    <th  className={styles.th}>Reporting</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td style={borderTop}>Structured Excel files with tables, charts, and formatting </td>
                                </tr>
                                <tr>
                                    <td style={borderTop}>Text files (csv / txt) for upload </td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Customizable email formats with attachments </td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Structured data formats- xml, dbf, json </td>

                                </tr>
                                <tr>
                                    <td style={borderTop}> Document types- PDF, Word </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            )
        case 3:
            return (
                <div id="Data-Operations" >
                    < div className="table-responsive tr-bg" >
                        <table className={`table ${styles.table}`}>
                            <thead>
                                <tr>
                                    <th  className={styles.th}>Governance</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td style={borderTop}>Role-based user management </td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Multi-level access control</td>
                                </tr>

                                <tr>
                                    <td style={borderTop}>Comprehensive audit trails</td>

                                </tr>
                                <tr>
                                    <td style={borderTop}>Automatic alerts</td>

                                </tr>
                                <tr>
                                    <td style={borderTop}>Active directory integration</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            )
        default:
            break;
    }
}

export default class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            divStyles: createStyles(0),
            hideProductDetails: false
        }
    }

    tick = () => {
        this.setState({
            count: this.state.count + 1,
            divStyles: createStyles(this.state.count + 1)
        });

        if (this.state.count === 4) {
            this.resetTimer();
        }

    }

    componentDidMount = () => {
        this.startTimer(0);
    }


    resetTimer = () => {
        this.setState({
            count: 0,
            divStyles: createStyles(0)
        });
    }

    startTimer = (i) => {
        this.timer = setInterval(this.tick, 8000);
        this.setState({
            count: i,
            divStyles: createStyles(i)
        })

    }

    stopTimer = (i) => {
        clearInterval(this.timer);
        this.setState({
            count: i,
            divStyles: createStyles(i)
        });

    }

    upvotes = (e) => {
        e.preventDefault();
        //do something...
    }

    render() {

        return (

            <div>
                <section className="section " id="product" data-spy="scroll">
                    < div className="container color-gray"  >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`section-header  text-center ${styles.sectionHeader}`} >

                                    < h3 className="text-capitalize color-pri-blue" >
                                        <span>A Platform for all your</span>

                                        < div className="rw-words rw-words-1" >
                                            <span>Reporting</span>
                                            <span>Processing</span>
                                            <span>Automation</span>
                                        </div>
                                        < span className="needs" > Needs </span>
                                    </h3>


                                </div>

                                <div className="row color-gray" >

                                    <div id="wrap" className="col-lg-6 col-md-12 col-sm-12">

                                        <span onMouseEnter={() => this.stopTimer(0)}
                                            onMouseLeave={() => this.startTimer(0)}
                                            style={this.state.divStyles[0]}
                                            onClick={e => this.upvotes(e)}>

                                            <img src={integration} alt="integrate" className={styles.integration}  />

                                        </span>

                                        <span className="bg" onMouseEnter={() => this.stopTimer(1)}
                                            onMouseLeave={() => this.startTimer(1)}
                                            style={this.state.divStyles[1]}
                                            onClick={e => this.upvotes(e)}>

                                            <img src={automation} className={styles.automation} alt="automate" />

                                        </span>

                                        <span className="bg" onMouseEnter={() => this.stopTimer(3)}
                                            onMouseLeave={() => this.startTimer(3)}
                                            style={this.state.divStyles[3]}
                                            onClick={e => this.upvotes(e)}>

                                            <img src={governance} alt="governance" className={styles.governance} />
                                        </span>

                                        <span className="bg" onMouseEnter={() => this.stopTimer(2)}
                                            onMouseLeave={() => this.startTimer(2)}
                                            style={this.state.divStyles[2]}
                                            onClick={e => this.upvotes(e)}>

                                            <img src={reporting} alt="report" className={styles.reporting} />
                                        </span>

                                        <span style={{ cursor: "pointer" }} id="sf-logo"
                                            onClick={e => this.upvotes(e)}>

                                            <div>
                                                <img src={logo} alt="logo" className={`img-responsive ${styles.logo}`}  />
                                            </div>

                                        </span>
                                    </div>

                                    <div id="showcontent" className="col-lg-6 col-md-12 col-sm-12">
                                        {selectedContent(this.state.count)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ div>
                </section>
            </div>

        )
    }
}
