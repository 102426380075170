import React from "react";

import Slider from "react-slick";
import "../styles/main.css";
import Blocks from "../images/slider-imgs/Blocks.gif";
import arrow from "../images/slider-imgs/Picture7.png";
import Computer from "../images/slider-imgs/Computer.gif";
import server from "../images/slider-imgs/server.gif";
import sk from "../images/slider-imgs/sk.jpg";
import Gears from "../images/slider-imgs/Gears.gif";
import setup from "../images/slider-imgs/setup.gif";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import { StaticQuery, graphql } from "gatsby";
import styles from "./SimpleSlider.module.css";

var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  cssEase: "linear",
  accessibility: true,
  adaptiveHeight: false,
};

export default () => (
  <StaticQuery
    query={graphql`
      query productsliderQuery {
        productsliderheadingsYaml {
          HeadingRad
          HeadingIms
          HeadingAbp
          HeadingScr
        }

        productsliderdescriptionsYaml {
          DescRAD
          DescIMS
          DescABP
          DescSCR
        }
      }
    `}
    render={(data) => (
      <section className="section " id="fast-track" data-spy="scroll" style={{ background: "#1e4a8d" }}>
        <div
          className="container-fluid banner"
          
        >
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <div className="slogan text-center color-white ">
                {/* <h1>Fast-track your Automation</h1> */}
                <section className="slider1" id="simpleSlider">
                  <Slider {...settings}>
                    <div className="rad">
                      <h3 className={styles.fontBold}>
                        {data.productsliderheadingsYaml.HeadingRad}
                      </h3>
                      <br />
                      <div className="rad-imgs">
                        <div className="rad-img1">
                          <img
                            src={Blocks}
                            className="img-responsive"
                            width="278"
                            height="276"
                            alt="Blocks"
                          />
                        </div>
                        <div className="rad-img2">
                          <img
                            src={arrow}
                            className="img-responsive"
                            width="66"
                            height="24"
                            alt="arrow"
                          />
                        </div>
                        <div className="rad-img3">
                          <img
                            src={Computer}
                            className="img-responsive"
                            width="278"
                            height="233"
                            alt="Computer"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="rad-footer">
                        <h4 className={styles.fontNormal}>
                          {data.productsliderdescriptionsYaml.DescRAD}
                        </h4>
                      </div>
                    </div>

                    <div className="ims">
                      <h3 className={styles.fontBold}>
                        {data.productsliderheadingsYaml.HeadingIms}{" "}
                      </h3>
                      <br />
                      <div className="rad-imgs">
                        <div className="rad-img1">
                          <img
                            src={server}
                            alt="server"
                            className="img-responsive"
                            width="278"
                            height="278"
                          />
                        </div>
                        <div className="rad-img2">
                          <img
                            src={arrow}
                            alt="arrow"
                            className="img-responsive"
                            width="66"
                            height="24"
                          />
                        </div>
                        <div className="rad-img3">
                          <img
                            src={sk}
                            alt="sk"
                            className={`img-responsive ${styles.sk}`}
                            width="278"
                            height="278"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="ims-footer">
                        <h4 className={styles.fontNormal}>
                          {data.productsliderdescriptionsYaml.DescIMS}
                        </h4>
                      </div>
                    </div>

                    <div className="automate">
                      <h3 className={styles.fontBold}>
                        {data.productsliderheadingsYaml.HeadingAbp}
                      </h3>
                      <br />
                      <div className="rad-imgs">
                        <div className="rad-img1">
                          <img
                            src={sk}
                            alt="sk"
                            className={`img-responsive ${styles.sk}`}
                            width="278"
                            height="278"
                          />
                        </div>
                        <div className="rad-img2">
                          <img
                            src={arrow}
                            alt="arrow"
                            className="img-responsive"
                            width="66"
                            height="24"
                          />
                        </div>
                        <div className="rad-img3">
                          <img
                            src={Gears}
                            alt="Gears"
                            className="img-responsive"
                            width="278"
                            height="187"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="automate-footer">
                        <h4 className={styles.fontNormal}>
                          {data.productsliderdescriptionsYaml.DescABP}
                        </h4>
                      </div>
                    </div>

                    <div className="report">
                      <h3 className={styles.fontBold}>
                        {data.productsliderheadingsYaml.HeadingScr}
                      </h3>
                      <br />

                      <div className="rad-imgs">
                        <div className="rad-img1">
                          <img
                            src={sk}
                            alt="sk"
                            className={`img-responsive ${styles.sk}`}
                            width="278"
                            height="278"
                          />
                        </div>
                        <div className="rad-img2">
                          <img
                            src={arrow}
                            alt="arrow"
                            className="img-responsive"
                            width="66"
                            height="24"
                          />
                        </div>
                        <div className="rad-img3 report-img3">
                          <img
                            src={setup}
                            alt="setup"
                            className={`img-responsive ${styles.setup}`}
                            width="300"
                            height="259"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="report-footer">
                        <h4 className={styles.fontNormal}>
                          {data.productsliderdescriptionsYaml.DescSCR}
                        </h4>
                      </div>
                    </div>
                  </Slider>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  />
);

// export default SimpleSlider;
