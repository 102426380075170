import React, { Component } from 'react'

import Slider from "react-slick";
import citruspayu from '../images/clients/citrus-payu.png'
import logo_fimmda from '../images/clients/logo_of_fimmda.png'
import mcx from '../images/clients/mcx.png'
import hdfc from '../images/clients/hdfc.png'
import abcmf from '../images/clients/ABCMF.jpg'
import idfcmf from '../images/clients/IDFC.png'
import npci from '../images/clients/NPCI.png'
import dsp from '../images/clients/dsp.png'
import styles from './TestimonialsClients.module.css';

class TestimonialsClients extends Component {
  render() {
    //  var imgStyles = {
    //    width: '100%',
    //    maxWidth: '120px'
    //  }
    var settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 5,

      autoplay: true,

      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
      ]
    };

    return (
      <div>
        <div id="slider" className={`flexslider ${styles.flexslider}`} >
          <div id="carousel">
            <Slider {...settings}>

              <div>
                <img src={logo_fimmda} className={styles.width100} alt="logo fimmda" />
              </div>
              < div >
                <img src={citruspayu} className={styles.width150} alt="citruspayu logo" />
              </div>
              <div>
                <img src={abcmf} className={styles.width100} alt="abcmf logo" />
              </div>
              <div>
                <img src={hdfc} className={styles.width150} alt="hdfc logo" />
              </div>
              <div>
                <img src={idfcmf} className={styles.width150} alt="idfc logo" />
              </div>
              <div>
                <img src={mcx} className={styles.mcx} alt="mcx logo" />
              </div>
              <div>
                <img src={npci} className={styles.width150} alt="npci logo"/>
              </div>
              <div>
                <img src={dsp} className={styles.dsp} alt="dsp logo" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

export default TestimonialsClients;
