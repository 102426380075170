import React from 'react'
//import img from '../images/video.png';
import styles from './Video.module.css';

export default function Video() {
//let imgUrl = img;
return (
<div>
  <section className={`section ${styles.section}`}>
    <div className="container">
      < div className="row color-gray row sk-video-row">
        < div className={`container sk-promo-video ${styles.skPromoVideo}`} >
          <iframe height="500" src="https://www.youtube.com/embed/7mXlAyuRlB4?rel=0" frameBorder="0" title="SK Video"
              allow="encrypted-media" allowFullScreen id="video-desc" className={styles.videoDesc} ></iframe>
        </div>
      </div>
    </div>
  </section>
</div>
)
}