import React from 'react'
import shridhar from '../images/team/Shridhar.jpg'
import Koustubh from '../images/team/Koustubh.jpg'
import chetan from '../images/team/chetan-400.jpg'
import surya from '../images/team/surya.jpg'
import styles from "./ManagementTeam.module.css"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import '../styles/main.css'

export default function ManagementTeam() {
    return (
        <div>
            <section id="team" className="bg-white section " data-spy="scroll">
                < div className="container" >
                    <div className={`row text-center ${styles.block} `} >
                        <h2 className="section-header">Management Team</h2>

                        < div className="col-md-4 col-xs-12 team-member" >
                            <img className="img-responsive img-thumbnail center-block" src={shridhar} alt="Sridhar Iyer" />
                            <h4>Shridhar Iyer</h4>
                            <h5>Chief Executive Officer</h5>
                            < a className="faLinkedin"
                                target="_blank"
                                href="https://www.linkedin.com/in/shridhar-iyer-b7775020/"
                                rel="noopener noreferrer" > </a>
                        </div>
                        < div className="col-md-4 col-xs-12 team-member" >
                            <img className="img-responsive img-thumbnail center-block" src={Koustubh} alt="Koustubh Moharir" />
                            <h4>Koustubh Moharir</h4>
                            <h5>Chief Technology Officer</h5>
                            < a className="faLinkedin"
                                target="_blank"
                                href="https://www.linkedin.com/in/koustubhmoharir/"
                                rel="noopener noreferrer" > </a>
                        </div>
                        < div className="col-md-4 col-xs-12 team-member" >
                            <img className="img-responsive img-thumbnail center-block chetan" src={chetan} alt="Chetan Lalwani" />
                            <h4>Chetan Lalwani</h4>
                            <h5>Director</h5>
                            < a className="faLinkedin"
                                target="_blank"
                                href="https://www.linkedin.com/in/chetan-lalwani-aa350215/"
                                rel="noopener noreferrer" > </a>
                        </div>
                        < div className="clearfix" > </div>
                        <br />
                        <hr />
                        <br />
                        <div className={`text-center ${styles.block}`} >
                            < h2 className="section-header second-section" > Advisors </h2>
                            < div className="col-sm-12  col-xs-12 team-member" >
                                <img className="img-responsive img-thumbnail center-block " src={surya} alt="surya" />
                                <h4>Surya Kumar Saripella</h4>
                                <a className="faLinkedin" target="_blank" href="https://www.linkedin.com/in/surya-kumar-saripella/" rel="noopener noreferrer"></a>
                            </div>

                        </div>
                        < div className="clearfix" > </div>

                    </div>
                </div>
            </section>
        </div>
    )
}
