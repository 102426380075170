import React, { useState } from 'react';
import { Link } from 'gatsby';
import Carousel from 'react-bootstrap/Carousel';
import "../styles/main.css";
import reconImg from "../../static/marketing-assets/images/new/pcImg.jpg";
import regReportImg from "../../static/marketing-assets/images/banner-img.png";

export default function LPSlider() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const lpSider = {
        width: '100%',
        // height: '500px',
        textAlign: 'center'
    }
    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>

                <div style={lpSider} className="lpSlider">
                    <img
                        className="img-thumbnail "
                        src={reconImg}
                        alt="Automate with our unique Reconciliation Software Service"

                    />
                    <Carousel.Caption>
                        <Link to="/reconciliation/" style={{ color: '#fff', textDecoration: 'none' }}>
                            <h2 className="lp-slider-h2">Automate with our unique Reconciliation Software Service</h2>
                            <p>Get a tailor-made solution with our Enterprise Reconciliation Software.</p>
                        </Link>
                    </Carousel.Caption>
                </div>

            </Carousel.Item>

            <Carousel.Item>

                <div style={lpSider} className="lpSlider">
                    <img
                        className="img-thumbnail "
                        src={regReportImg}
                        alt="De-risk your regulatory reporting"

                    />
                    <Carousel.Caption>
                        <Link to="/regulatory-reporting/" style={{ color: '#fff', textDecoration: 'none' }}>
                            <h2 className="lp-slider-h2">De-risk your regulatory reporting</h2>
                            <p>Keep up with frequent revisions in regulatory requirements with 100% accuracy.</p>
                        </Link>
                    </Carousel.Caption>
                </div>

            </Carousel.Item>

        </Carousel>
    )
}
