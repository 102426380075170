import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css';

import styles from './index.module.css'
import '../styles/main.css'
import '../styles/demo.css'
import '../styles/sk-styles.css'
import '../styles/sk-changes.css'

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Header from '../components/Header'
import SimpleSlider from '../components/SimpleSlider.js';
import FeaturedPost from '../components/FeaturedPost.js';
import ProductDetails from "../components/ProductDetails.js";
import Video from "../components/Video.js";
import Testimonials from '../components/Testimonials.js';
import testimonials from '../images/testimonial-bg.png';
import TestimonialsClients from "../components/TestimonialsClients.js";
import ManagementTeam from '../components/ManagementTeam.js';
import LetsTalk from '../components/LetsTalk.js';
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import UseCases from '../components/UseCases.js';
// import Fade from 'react-reveal/Slide';
import { Helmet } from 'react-helmet';
import { graphql } from "gatsby";

let imgUrl = testimonials;


export default class BlogIndex extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, false);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);

    }

    closeToast() {
        let toastStyle = document.getElementById('toast').style;
        toastStyle.animationDuration = '0.5s';
        toastStyle.animationFillMode = 'forwards';
        toastStyle.animationTimingFunction = 'ease-out';
        toastStyle.animationName = 'slideout';
    }

    showToast() {
        let toastStyle = document.getElementById('toast').style;
        toastStyle.animationDuration = '0.5s';
        toastStyle.animationFillMode = 'backwards';
        toastStyle.animationTimingFunction = 'ease';
        toastStyle.animationName = 'slidein';
    }

    onScroll = (e) => {
        if (window.pageYOffset > 20) {
            this.closeToast();
        }
        else {
            this.showToast();
        }
    }

    render() {
        const edges = this.props.data.allMarkdownRemark.edges;
        const hasFeaturedPost = !!edges.length;
        const featuredPost = hasFeaturedPost && <FeaturedPost featuredPost={edges[0].node} /> // only 1 edge is fetched from graphql with featured filter
        const latestVersion = this.props.data.releaseVersion.edges[0].node.version;
        return (
            <div>
                <Helmet>
                    <meta http-equiv="cache-control" content="max-age=0" />
                    <meta http-equiv="cache-control" content="no-cache" />
                    <meta http-equiv="expires" content="-1" />
                    <meta http-equiv="expires" content="Tue, 01 Jan 1980 11:00:00 GMT" />
                    <meta http-equiv="pragma" content="no-cache" />
                    <meta name="description" property="og:description" content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications" />
                    <meta name="title" property="og:title" content="SheetKraft" />
                    <meta name="image" property="og:image" content="https://www.sheetkraft.com/marketing-assets/images/sk-logo-sticky.png" />
                    <meta name="author" content="SheetKraft" />
                    <meta name="keywords"
                        content=" automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation" />
                    <title>SheetKraft</title>
                </Helmet>
                <div className={styles.bgSecBlue} id="header">
                    <Header samePage={true} />
                    <SimpleSlider id="productSlider" />
                </div>
                <div id="productDetails">
                    <ProductDetails />
                </div>
                <div id="useCases">
                    <UseCases />
                </div>

                <div id="featuredPost">
                    {featuredPost}
                </div>
                <div id="video" >
                    <Video />
                </div>

                {/******Testimonials*******/}
                <section className="section " id="testimonials" data-spy="scroll"
                    style={{ backgroundImage: "url(" + imgUrl + ")" }}>
                    < div className="container ">
                        < div className="row">
                            <div className="col-lg-12">
                                < div className="sk-testimonials-text">
                                    < div className="sk-testimonials-title"> Testimonials</div>
                                </ div>
                            </ div>
                        </ div>
                        < div className="row">
                            <div className="col-lg-12">
                                <Testimonials />
                                <TestimonialsClients />
                            </div>
                        </div>

                    </div>
                </section>
                {/******Testimonials*******/}
                <div id="managementTeam">
                    <ManagementTeam />
                </div>
                <div id="letsTalk">
                    <LetsTalk />
                </div>
                <div id="footer">
                    <SheetkraftFooter />
                </div>
                <div className="container-fluid showToast" >
                    <div className="toast" data-autohide="false" id="toast">
                        <div className="toast-body">
                            <a href="docs/release-notes/" id="versionLink" className={styles.versionLink}>Sheetkraft version {latestVersion} is officially released</a>
                        </div>
                    </div>
                </div>
                <AnchorLink href='#header' className="scrollup" > < i className="fa fa-chevron-up"> </i> </AnchorLink>
            </div>

        )
    }
}

export const query = graphql`
    {
        allMarkdownRemark(sort: {
            fields: frontmatter___published,
            order: DESC
        }, filter: {
            frontmatter: {
                draft: {
                    ne: true
                },
                category: {
                    eq: "news"
                },
                featured: {
                    eq: true
                }
            }
        }, limit: 1) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        tags
                        category
                        draft
                        published
                        title
                        featured
                        hero {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    excerpt(pruneLength: 1000)
                    html
                }
            }
        }

        releaseVersion:allIndexYaml(limit: 1) {
            edges {
              node {
                version
              }
            }
          }
    }
`