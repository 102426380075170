import React from "react";
import { Link } from "gatsby";
import styles from "./featuredpost.module.css";
import "../styles/main.css";
import excelAutomate from '../images/excel-automation-jason.jpg';
// import Img from 'gatsby-image'

export default function FeaturedPost(props) {
  let post = props.featuredPost;
  let postExcerptLength = post.excerpt.length;

  let excerpt = postExcerptLength > 500 ? (post.excerpt.substring(0, 500) + '...').replace(
    /http[^)\s]+/,
    '<a href="$&" target="_blank" rel="noreferrer">$&</a>'
  ) : ''; // Hack for Bizlabs post to allow link
  return (
    <div>
      <section className={`${styles.section}`}>
        <div className="container">
          <h2 className={styles.sectionHeader}> Featured News & Video </h2>
          <div className={`row ${styles.rowStyle}`}>
            <div className={`col-lg-6 ie-display-ib`} id="first" style={{ padding: '40px 20px' }}>
              <div>
                <a href='https://intellyx.com/2020/09/15/sheetkraft-turbocharging-excel-for-complex-business-challenges/' target="_blank" rel="noreferrer noopener" className={styles.linkHover}>
                  <h4 className={styles.textColor}>
                    {/* Low Code Automation Platform - The next must thing for your business */}
                    Hear it from the industry expert
                  </h4>
                </a>
                <p style={{ marginTop: '20px' }}>
                <a href='https://www.linkedin.com/in/jasonbloomberg' target="_blank" rel="noreferrer noopener" className={styles.linkHover} style={{fontSize:'18px'}}> Jason Bloomberg </a> - a leading digital transformation guru, talks about how SheetKraft solves the major issue being faced by many organizations where Excel falls short of the capabilities that complex use cases often require – even for power users. SheetKraft
                  resolves this problem by extending Excel via a combination of sophisticated Excel plugins and a server-based platform offering.
                  &nbsp;<a href='https://intellyx.com/2020/09/15/sheetkraft-turbocharging-excel-for-complex-business-challenges/' target="_blank" rel="noreferrer noopener" className={styles.linkHover}>Read More...</a>
                </p>
              </div>
            </div>
            <div
              className={`col-lg-6  ${styles.padding}`}
              id="second"
            >
              <div>
                <img
                  src={excelAutomate}
                  className={`img-fluid img-thumbnail`}
                  alt="excel-automation-jason"
                  style={{
                    boxShadow: "none",
                    width: '100%',
                    height: '250px'
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className={`row ${styles.rowStyle}`}>
            <div className="col-lg-6" id="second">
              <div className={`${styles.featured} `}>
                <img
                  src={post.frontmatter.hero.childImageSharp.fluid.src}
                  className={`img-fluid img-thumbnail`}
                  alt="featured"
                  style={{
                    boxShadow: "none",
                  }}
                />
              </div>
            </div>
            <div className={`col-lg-6 ie-display-ib ${styles.flex1}  padding20`} id="first">
              <div>
                <Link to={post.fields.slug} className={styles.linkHover}>
                  <h4 className={styles.textColor}>{post.frontmatter.title}</h4>
                </Link>
                <p
                  dangerouslySetInnerHTML={{
                    __html: excerpt
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
