import React from 'react'

import Slider from "react-slick";
import '../styles/demo.css';
import '../styles/sk-changes.css';
import '../styles/flexslider.css';
import styles from './Testimonials.module.css';
import abcmf from '../images/clients/ABCMF.jpg'
import abcpms from '../images/clients/ABCPMS.png'
import citrus from '../images/clients/citrus.jpg'
import { StaticQuery, graphql } from 'gatsby';
import '../../node_modules/font-awesome/css/font-awesome.min.css';

 var settings = {
     dots: true,
     infinite: true,     
     slidesToShow: 1,
     slidesToScroll: 1,
     autoplay: true,       
     speed: 500,     
     autoplaySpeed:8000,
     cssEase: "linear",
     accessibility: true,
};
 

export default () => (

    <StaticQuery query = { graphql `
       
      query testimonialQuery {
        
        
        testimonialscontentYaml {
            CMF
            PMS
            Citrus
        }


      }
    `}
        
        render={ data => (
               
                 <div>
               <section className="slider2">
                    <div id="slider" className={`flexslider ${styles.slider}`}>
                <Slider {...settings}>              
                  
                                < div className = "sk-testimonial-card" >
                                    <p>{data.testimonialscontentYaml.CMF}
                                    </p>
                                    < div className = "sk-testimonial-content" >
                                        <img src={abcmf} className={styles.width100} alt="abcmf logo"/>
                                        <div>
                                            <h1>Operations Head</h1>
                                            <h2>Aditya Birla CMF</h2>
                                        </div>
                                    </div>
                                </div>
                           
                                < div className = "sk-testimonial-card">
                                    <p>{data.testimonialscontentYaml.PMS}</p>
                                    < div className = "sk-testimonial-content" >
                                        <img src={abcpms} className={styles.width100} alt="abcmps logo"/>
                                        <div>
                                            <h1>Operations Head</h1>
                                            <h2>Aditya Birla PMS</h2>
                                        </div>
                                    </div>
                                </div>
                          
                                < div className = "sk-testimonial-card">
                                    <p>{data.testimonialscontentYaml.Citrus}</p>
                                    < div className = "sk-testimonial-content" >
                                        <img src={citrus} className={styles.width100} alt=" citrus logo"/>
                                        <div>
                                            <h1>Operations Head</h1>
                                            <h2>Citrus Payment</h2>
                                        </div>
                                    </div>
                            </div>
                      </Slider>
                   </div>
               </section>
           </div>
                
             )
             }
             />
             )
 

