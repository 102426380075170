import React from 'react'
import LPSlider from '../components/LP-Slider'
export default function UseCases() {
    const sectionStyle = {
        background: ' #f1f1f1',
        padding: '100px 0'
    }
    const sectionHeader = {
        fontSize: '2.3em',
        fontWeight: '500',
        textAlign: ' center',
        padding: '0px 0 40px',
        marginTop: '0px',
        color: '#2d2d2d'
    }
    return (
        <div>
            <section style={sectionStyle}>
                <div className="container">
                    <h2 style={sectionHeader}> Use Cases </h2>
                    <div >
                        <LPSlider />
                    </div>
                </div>
            </section>
        </div>
    )
}
